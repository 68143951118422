<template>
  
      <ion-grid class="Contactanos_grid_studio">
        <ion-row>
          <ion-col class="ion-align-items-center">
            <h3>¡Contáctanos!</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <span>
              <a target="_blank" href="https://api.whatsapp.com/send?phone=5215568725539&text=Hola!%20Me%20gustar%C3%ADa%20tener%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20los%20servicios%20de%20Vybroo.">
                <ion-icon slot="start" src="assets/ICONOS/whatsapp.svg"></ion-icon>
              </a>
            </span>
            <span>
              <a target="_blank" href="mailto:contacto@vybroo.com">
                <ion-icon slot="start" src="assets/ICONOS/Servicios/correo.svg"></ion-icon>
              </a>
            </span>
          </ion-col>
        </ion-row>
      </ion-grid>
      
</template>

<script>
export default {
    name:"footerContactanos"
}
</script>
