<template>

  <ion-toolbar class="header_app">
    <ion-buttons slot="start">
      <ion-menu-button class="conf_toolbar" menu="menustudio">
            <ion-img src="assets/ICONOS/dex_tabs.png"></ion-img>
      </ion-menu-button>
    </ion-buttons>

    <ion-buttons v-if="sonara" class="logo_sonara" slot="end" style="">
      <ion-img src="assets/ICONOS/logo-sonara.png"></ion-img>
    </ion-buttons>
    <ion-buttons v-else class="logo_toolbar" slot="end" style="">
      <ion-img src="assets/ICONOS/vybroo-logo.svg"></ion-img>
    </ion-buttons>
    <ion-menu-button class="dex_tabs" v-if="!sonara" slot="end" menu="menudex">
        <ion-icon class="i_conf" src="assets/ICONOS/configuracion.svg"></ion-icon>
        <ion-icon class="i_plus" src="assets/ICONOS/plus.svg"></ion-icon>
    </ion-menu-button>
    <ion-title>{{ $route.params.id }}</ion-title>
  </ion-toolbar>

</template>

<!-- <script>
export default {
    name:"headertoolbar"
}
</script> -->


<script>
/* eslint-disable */
export default {
  name: "headertoolbar",
  data() {
    return {
      sonara: false,
    };
  },
  mounted() {
    this.emitter.on("sonara", (data) => {
      this.sonara = data;
      console.log("sonara", this.sonara);
    });
  }
};
</script>